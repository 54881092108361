#mission {
  .text-container {
    @screen lg {
      max-width: 836px;
    }
    p {
      &:first-child {
        font-family: $font-oswald;
      
        font-size: 22px;
        @screen lg {
            line-height: 86px;
          font-size: 28px;
        }
      }
      &:nth-child(2) {
        font-family: $font-roboto;
         font-size: 16px;
        line-height: 20px;
        @screen lg {
            font-size: 18px;
            line-height: 24px;
        }
      }
    }
  }
}
