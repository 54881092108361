@font-face{
    font-family: 'Roboto';
    src: url("./../media/fonts/Roboto-Regular.ttf")
}

@font-face{
    font-family: 'Roboto';
    font-weight: bold;
    src: url("./../media/fonts/Roboto-Bold.ttf")
}

@font-face{
    font-family: 'Oswald';
    src: url("./../media/fonts/Oswald-Regular.ttf")
}

@font-face{
    font-family: 'Oswald';
    font-weight: bold;
    src: url("./../media/fonts/Oswald-Bold.ttf");

}




$font-roboto:'Roboto', Helvetica, Arial, sans-serif;
$font-oswald:'Oswald', Helvetica, Arial, sans-serif;