#focus {
  background-image: url("/../media/images/Mobile/blue-img.png");
  background-size: cover;
  background-position: center;
  height: 901px;
  width: 100%;
  @screen lg {
    height: 660px;
    background-image: url("/../media/images/Desktop/blue-img.png");
  }
  h2 {
    font-family: $font-oswald;
    font-size: 22px;
    @screen lg {
      line-height: 86px;
      font-size: 28px;
    }
  }
  p {
    font-family: $font-roboto;
    line-height: 20px;
    @screen lg{
      max-width: 795px;
    }
    &.info {
      max-width: 400px;
      @screen lg{
        font-size:18px;
        line-height:24px;
        max-width: 795px;
      }
    
  }
  }
  .container-dots {
    .dot {
      padding: 18px 0;
      @apply flex flex-row items-center;
      @screen lg {
        @apply flex-col  mx-10 my-0;
      }
      img {
        width: 78.4px;
        height: 78.4px;
        @screen lg {
          width: 111px;
          height: 111px;
          @apply m-auto pr-0;
        }
      }
      p {
        font-size: 20px;
        line-height: 24px;
        @apply font-bold;
        @screen lg {
          max-width: 160px;
          @apply pt-6;
        }
      }
    }
  }
}
