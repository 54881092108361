#hero {
  background-image: url("/../media/images/Mobile/Mobile-Hero.png");
  background-size: cover;
  background-position: bottom;
  object-fit: contain;
  height: 415px;
  width: 100%;

  @screen lg {
    background-image: url("/../media/images/Desktop/hero.png");
    height: 950px;
  }
  .text-container {
    @screen lg {
      margin-top: 92px;
    }
    .logo-mobile {
      img {
        width: 67px;
        height: 77px;
      }
    }
    .title-text {
      font-family: $font-oswald;
      font-weight: bold;
      text-align: center;
      letter-spacing: -0.02em;
      font-size: 28px;
      line-height: 32px;
      @screen lg {
        font-size: 50px;
      }
      @screen lg {
        font-size: 70px;

        line-height: 74px;
      }
    }
    .text {
      max-width: 550px;
      line-height: 14px;
      @screen lg {
        max-width: 868px;
        line-height: 24px;
      }
    }
  }
}
