#menu {
  .links {
    a {
      width: 125px;
      @apply text-white font-bold inline-block text-lg text-center cursor-pointer;
      img {
        width: 125px;
        height: 143px;
      }
      &:hover {
        opacity: .7;
      }
      @screen md {
        @apply text-base whitespace-no-wrap;
	  }
	  @screen lg {
        @apply mx-6;
	  }
	  
      @screen xl {
        @apply text-lg mx-12;
      }
    }
  }
  &.scrolling {
    @screen md {
      transition: all 0.3s linear;
    }
  }
}
