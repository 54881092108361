@tailwind base;
@tailwind components;

@import "typo";
@import "_menu";
@import "styles";
@import "hero";
@import "first";
@import "second";
@import "third";
@import "four";
@import "footer";

@tailwind utilities;
