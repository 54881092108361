#involved {
  .container {
    max-width: 1200px;
    .container-form {
      p {
        max-width: 480px;
        font-family: $font-roboto;
 
        line-height: 20px;
 
        &:first-child {
          font-size: 22px;
          font-family: $font-oswald;
          @screen lg {
            font-size: 28px;
            text-align:left;
          }
        }

        
      }
      .form {
        font-family: $font-roboto;
        textarea {
          padding: 14px;

        }
.input-container{
  @screen lg {
  width:478px;
  }
}
        input,
        textarea {
          width: 100%;
          max-width: 348.22px;
          margin: 12px 0;
          border: 0.5px solid #000000;
          @screen lg{
            max-width:478px;
          }
          &.input-name,
          &.input-email,
          &.submit {
            height: 58px;
            padding-left: 14px;
          }
          &.input-message {
            height: 140px;
          }
          &.submit {
            background-color: #42b8f5;
            font-family: $font-oswald;
            font-weight: bold;
            font-size: 24px;
            color: #ffffff;
            text-transform: uppercase;
            border: none;
            &:hover {
              background-color: rgb(66, 184, 245, 0.7);
            }
            @screen lg{
            margin-bottom:0;
            padding-bottom:0;
            max-width:298px;
            }
          }
        }
      }
    }
  }
}
