#donate {
  .container {
    max-width: 1200px;
    h2 {
      font-family: $font-oswald;
      font-size: 22px;

      @apply font-bold text-blueNavy pb-2;
      @screen lg {
        line-height: 56px;
        font-size: 28px;
        @apply pb-0;
      }
    }
    a,
    span,
    p {
      font-family: $font-roboto;
      line-height: 24px;
      @apply text-blueDenim;
    }
    .logo-container {
      @apply pb-1;
      img {
        height: 85px;
        width: 75px;
        @screen lg {
          width: 120px;
          height: 137px;
        }
      }
    }
    .contact-container {
      max-width: 289px;
      @screen xl {
      }
    }
    .donate-container {
      max-width: 259px;
      @screen xl {
        @apply;
      }
    }
    .contact-container,
    .donate-container {
      @apply py-5;
      @screen xl {
        @apply py-0;
      }
    }

    .donate-container {
      @screen lg {
        @apply text-right;
      }
      a {
        font-family: $font-oswald;
        font-size: 24px;
        width: 221px;
        height: 58px;
        @apply bg-blueBright text-white font-bold flex justify-center items-center;
        &:hover {
          background-color: rgba(66, 184, 245, 0.9);
        }
      }
    }
  }
}
